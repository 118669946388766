/* @import url('https://fonts.googleapis.com/css2?family=Commissioner:wght@300;600&display=swap'); */


* {
  margin: 0;
  padding: 0;
  /* background: #0f0f0f; */
  /* color:#fcfcdb; */
  /* font-family: 'Commissioner', sans-serif; */
}
/* 
body {
  font-family: 'Commissioner', sans-serif;
  background: #0f0f0f;
  color:#fcfcdb;
  font-family: 'Commissioner', sans-serif;
  font-weight:300;
} */

body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', 'Commissioner',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color:black;
  --bs-body-bg: #0b0b0b;
  /* background: #0f0f0f;
  breakpoints:'xxxl', 'xxl', 'xl', 'lg', 'md', 'sm', 'xs', 'xxs';
  minBreakpoint:"xxs"; */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', 'Commissioner',
    monospace;
}

.accordion{
  --bs-accordion-btn-bg: grey;
}