.navbar {
    padding: 0.5rem;
    height: 70px;
  }

/* .custom-navbar {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
} */
  
  /* .navbar-brand {
    font-size: 1.5rem;
    font-family: "Trajan", sans-serif;
  } */

  .navbar-brand {
    font-size: 1.5rem;
  }

  
    /* font-family: "GaneshaType", url(../assets/fonts/GaneshaType.ttf), sans-serif; */
    /* font-family: "GrecianC", url(../assets/fonts/GrecianC.ttf), sans-serif; */
    /* font-family: "GreekaTYGRA", url(../assets/fonts/greekatygra.ttf), Regular; */
    /* font-family: "HemicoGreek", url(../assets/fonts/HemicoGreek.ttf), sans-serif; */
    /* font-family: "VEGeorgianBrushCyrillicGreek", url(../assets/fonts/vegeorgianbrushcyrillicgreek.ttf), sans-serif; */

/* import GaneshaType from '../assets/fonts/GaneshaType.ttf';
import GrecianC from '../assets/fonts/GrecianC.ttf';
import GrecianStyle from "../assets/fonts/grecianstyle.ttf";
import GreekaTYGRA from "../assets/fonts/greekatygra.ttf";
import HemicoGreekRegular from "../assets/fonts/HemicoGreekRegular.ttf";
import VEGeorgianBrushCyrillicGreek from "../assets/fonts/vegeorgianbrushcyrillicgreek.ttf"; */




  .navbar-nav .nav-link {
    font-size: 1.2rem;
    padding: 0.25rem 0.5rem;
    /* font-family: 'GreekaTYGRA', sans-serif; */
  }
  


  .navbar-collapse {
    justify-content: flex-end;
  }
  
  .form-control {
    font-size: 1.2rem;
  }
